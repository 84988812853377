/*background*/
body {
  background-color: black;
  color: white;
  background-image: url('/public/img/red_curtain.jpg');
  background-repeat: repeat-y;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  /* gesamtes Dokument auf 10px setzen,
    damit mit "rem" einfacher zu rechnen ist: 16px = 1,6 rem */
  font-size: 10px;
  width: 95vw;
}

p {
  font-family: sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
}

div {
  font-family: sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
}

a {
  font-size: 2rem;
  font-family: sans-serif;
} /*u.a. für die Nav-Edit */

/* color of scrollbar */

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: darkgray rgba(0, 0, 0, 0.3);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 20px;
  border: 3px solid rgba(0, 0, 0, 0.3);
}
