/* Tabletcontainer */
#tabletcontainer {
  margin-top: 0rem;
  display: flex;
  justify-content: center;
  max-height: 100%;
}

/* Mobilecontainer + header*/
#mobilecontainer {
  display: grid;
  justify-content: center;
  margin-top: 1rem;
}
